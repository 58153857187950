<template>
  <div class="form-error">
    <p class="error-msg">{{message}}</p>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss">
@import '~sass/modules/_variables.scss';
@import '~sass/modules/_fonts.scss';

.form-error {
  background-color: $clr-bg-red;
  border: 1px solid $clr-border-red;
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  margin-bottom: 2%;
  .error-ico {
    float: left;
    width: 22px;
  }
  .error-msg{
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-size: 1rem;	
    color: $font-clr-black;
  }
}
</style>